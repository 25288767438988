import React from "react"
import PropTypes from "prop-types"

import { ApplyUrlProvider } from "@src/hooks/useApplyUrl"
import Page, { Footer, Header, HeaderContent } from "@src/components/Page"
import SuccessStoryCTA from "@src/components/SuccessStoryCTA"
import LoremIpsum from "@src/components/LoremIpsum"
import DefaultSeparator from "./_component_default_separator"

const SuccessStoryCTAPage = ({ location }) => {
  const title = "Success Story CTA"
  const breadcrumb = {
    page: {
      title,
    },
    slug: "SMALL_BUSINESS",
  }
  return (
    <ApplyUrlProvider
      location={location}
      attributionTopic="Small Business Loans"
    >
      <Page
        title={title}
        header={<Header />}
        headerContent={<HeaderContent title={title} breadcrumb={breadcrumb} />}
        footer={<Footer />}
      >
        <LoremIpsum />
        <SuccessStoryCTA />
        <LoremIpsum />
        <DefaultSeparator />
      </Page>
    </ApplyUrlProvider>
  )
}

SuccessStoryCTAPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
}

export default SuccessStoryCTAPage
